
import { defineComponent, ref } from 'vue';
import Header from '../components/header/Header.vue';
import isUser from '../context/user';
import User from '../types/User';
import UserDetails from '../types/UserDetails';

export default defineComponent({
  components: { Header },
  setup() {
    const userCheck = ref(false);
    const user = ref<User | null>(null);
    const userDetails = ref<UserDetails>();

    isUser(userCheck);
    if (userCheck.value) {
      user.value = JSON.parse(localStorage.getItem('user') || '{}');
    }

    const fetchUserDetails = async () => {
      try {
        if (user.value?.access_token) {
          const response = await fetch(
            `${process.env.VUE_APP_BACKEND_URL}/user/${user.value?.access_token}`
          );
          if (!response.ok) {
            throw Error('No data available');
          }
          const json = await response.json();
          const jsonObj = await JSON.parse(json);
          userDetails.value = jsonObj;
        }
      } catch (error) {
        error.value = error.message;
        console.log('Error: ', error.value);
      }
    };

    fetchUserDetails();

    const viewProfileOnMAL = () => {
      window.open(`https://myanimelist.net/profile/${user.value?.username}`);
    };

    return { user, userDetails, viewProfileOnMAL };
  }
});
